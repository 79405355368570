import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  //! -----------Home Page---------
  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home",
  },
  //!----------------Events--------
  {
    id: "events",
    title: "Events",
    type: "collapse",
    icon: <Icon.Mic size={15} />,
    children: [
      {
        id: "eventsList",
        title: "Events",
        type: "item",
        icon: <Icon.Mic size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/events/list",
      },
      {
        id: "eventsCategory",
        title: "Events Category",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/events-category/list",
      },

      {
        id: "commentslist",
        title: "Comments List",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/comments",
      },
    ],
  },

  //!----------------Variations--------
  {
    id: "variations",
    title: "Variations",
    type: "collapse",
    icon: <Icon.Activity size={15} />,
    children: [
      {
        id: "variation",
        title: "Variation",
        type: "item",
        icon: <Icon.AlignJustify size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/variations/list",
      },
      {
        id: "variationValue",
        title: "Variation Value",
        type: "item",
        icon: <Icon.AlignCenter size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/variations-value/list",
      },
    ],
  },

  //!----------------Product--------
  {
    id: "products",
    title: "Products",
    type: "collapse",
    icon: <Icon.Archive size={15} />,
    children: [
      {
        id: "Product",
        title: "Product",
        type: "item",
        icon: <Icon.AlignJustify size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product/list",
      },
      {
        id: "Category",
        title: "Category",
        type: "item",
        icon: <Icon.AlignJustify size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/category/list",
      },
      {
        id: "Sub Category",
        title: "Sub Category",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sub-category/list",
      },
      {
        id: "productsSorting",
        title: "Products Sorting",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/product/sort-list",
      },
    ],
  },
  //!--------------Pages List-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },
  //! -----------Static Pages---------
  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "About",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about",
      },
      {
        id: "Factories",
        title: "Factories",
        type: "item",
        icon: <Icon.Archive size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/factories",
      },
      // {
      //   id: "Features",
      //   title: "Features",
      //   type: "item",
      //   icon: <Icon.Menu size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/features",
      // },
      // {
      //   id: "privacyPolicy",
      //   title: "Privacy Policy",
      //   type: "item",
      //   icon: <Icon.List size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/privacy-policy",
      // },
      // {
      //   id: "TermsConditions",
      //   title: "Terms & Conditions",
      //   type: "item",
      //   icon: <Icon.List size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/terms-and-conditions",
      // },
      // {
      //   id: "Sustainability",
      //   title: "Sustainability",
      //   type: "item",
      //   icon: <Icon.Heart size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/sustainability",
      // },
    ],
  },

  //!---------------Reviews------
  {
    id: "reviews",
    title: "Reviews",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/reviews",
  },

  //!--------------Pages-------
  {
    id: "FAQS",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs",
  },
  {
    id: "ContactUsFormList",
    title: "Contact List",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-list",
  },

  // !---------------- Media--------
  // {
  //   id: "medialist",
  //   title: "Media",
  //   type: "item",
  //   icon: <Icon.Video size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/media/list",
  // },
  // //!---------------Users Quatation------
  {
    id: "usersQuatation",
    title: "User Quotations",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/user-quotation",
  },
  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;
