import axios from "axios";

const apiURL = "https://kuwaitpack.com/admin/public/v1/api";
export const API = axios.create({
  baseURL: apiURL,
  timeout: 90000,
});
export const APIPUt = axios.create({
  baseURL: apiURL,
  timeout: 90000,
});

API.interceptors.request.use(
  (request) => {
    if (request) {
      //perform the manipulation here and change the request object
    }
    return {
      ...request,
      onUploadProgress: function (progressEvent) {
        console.log(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    };
  },
  (error) => {
    console.log("interceptor request ERROR", error);

    return Promise.reject(error.message);
  }
);

API.interceptors.response.use(
  (response) => {
    if (response) {
      //perform the manipulation here and change the response object
    }
    return response;
  },
  (error) => {
    console.log("interceptor response ERROR", error);

    if (error.response?.status === 401) {
    }
    return Promise.reject(error.message);
  }
);
